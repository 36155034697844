import React, { useState } from 'react'

import arrow from '../../../images/arrow-up.svg'

import {
  Menu,
  Hide,
  Day,
  ListWrapper,
  List,
  NumberWrapper,
  Item,
  ItemWrapper,
} from './styles/indexStyle'

export default function Listing(props) {
  const [menu, setMenu] = useState(true)

  function listAttractionsOnDay(attractions) {
    return attractions.map((attraction, index) => {
      return (
        <ItemWrapper key={index}>
          <Item
            key={index}
            activeAttractionID={props.activeAttractionID}
            attractionsLenght={props.attractionsLenght}
            index={index}
            onClick={e => {
              e.preventDefault()
              props.goToAttraction(attraction.id)
              if (window.innerWidth < '1024') {
                window.location.href = '#map'
              }
            }}
          >
            <List
              key={index}
              id={attraction.id}
              activeAttractionID={props.activeAttractionID}
            >
              <NumberWrapper
                className="innerNumberWrapper"
                activeAttractionID={props.activeAttractionID}
                attractionsLenght={props.attractionsLenght}
              >
                <p>{attraction.id + 1}</p>
              </NumberWrapper>
              <h4 dangerouslySetInnerHTML={{ __html: attraction.stopName }} />
            </List>
          </Item>
        </ItemWrapper>
      )
    })
  }

  function btnToggle() {
    const currentState = menu
    setMenu(!currentState)
  }

  function displayAttractions() {
    let AttractionsData = props.activitiesData
    const attractionList = AttractionsData.map((attractions, index) => {
      return (
        <ListWrapper key={index} attractionsLenght={props.attractionsLenght}>
          <Day>DAY {index + 1}</Day>
          {listAttractionsOnDay(attractions, index)}
        </ListWrapper>
      )
    })

    return attractionList
  }

  return (
    <>
      <Hide
        className={menu ? 'open' : 'close'}
        onClick={e => {
          e.preventDefault()
          btnToggle()
        }}
        active={menu}
      >
        <img src={arrow} alt="Show / Hide Menu" />
        HIDE STOPS
      </Hide>
      <Menu active={menu} className={menu ? 'open' : 'close'}>
        {displayAttractions()}
      </Menu>
    </>
  )
}
