/* global mapboxgl */

import '../styles/mapbox.css'
import React from 'react'
import LoadExternalScript from '../../LoadExternalScript'

import Listing from './listing'

import { Sidebar, Map, Container } from './styles/indexStyle'
// import Testdata from './test'

var striptags = require('striptags')

export default class BallMap extends React.Component {
  constructor(props) {
    super(props)

    this.flyToAttraction = this.flyToAttraction.bind(this)

    this.map = null
    this.currentAttraction = 0
    this.currentCity = 0
    this.marker = 0
    this.attractions = []
    this.Attractions = []
    this.noSideBar = this.props.noSideBar === 'noSideBar' ? true : false
    this.AttractionsData = this.noSideBar
      ? this.props.nsbData
      : this.props.activitiesData

    this.attractionQuantity = 0
    this.mapSize = 500

    this.state = {
      data: this.Attractions,
      center: [143.84841, -37.55822],
      zoom: 11,
      activeAttractionID: 0,
      displaySideBar: true,
    }

    if(this.AttractionsData) {
      this.createId()
    }
  }

  createId() {
    for (let i = 0; i < this.AttractionsData.length; i++) {
      for (let j = 0; j < this.AttractionsData[i].length; j++) {
        this.Attractions.push(this.AttractionsData[i][j])
      }
    }
    this.Attractions = this.Attractions.map(function(attraction, index) {
      attraction.id = index
      return attraction
    })
  }

  componentDidMount() {
    if (typeof mapboxgl !== 'undefined') {
      this.createMap()
    }
  }

  setActiveAttraction(index) {
    this.setState({
      activeAttractionID: index,
    })
  }
  boundsView() {
    let coordinates = this.Attractions.map(attraction => {
      return [attraction.map.lat, attraction.map.lng]
    })

    let bounds = coordinates.reduce(function(bounds, coord) {
      return bounds.extend(coord)
    }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]))

    this.map.fitBounds(bounds, {
      padding: 100,
    })
  }

  createMap() {
    this.reset = () => {
      this.removePopups()
    }

    mapboxgl.accessToken =
      'pk.eyJ1IjoidmlzaXRiYWxsYXJhdCIsImEiOiJjanQ5aWw0a3MwMGxuM3lsNno1d29mMjQ2In0.c7entLvKAHX7RDv72p0iew'

    this.map = new mapboxgl.Map({
      center: this.state.center,
      zoom: this.state.zoom,
      container: 'map',
      style: 'mapbox://styles/visitballarat/cjt9mdyxm0e2c1flbsuepc4xh',
    })

    // this.map.addControl(new mapboxgl.NavigationControl())

    this.map.on('load', () => {
      this.setState({ displaySideBar: true })
      !this.noSideBar
        ? this.map.scrollZoom.disable()
        : this.map.scrollZoom.enable()
    })

    this.removeAttraction = () => {
      for (let i = 0; i < this.attractions.length; i++) {
        let attraction = this.attractions
        if (attraction[i]) attraction[i].remove()
        this.attractions = attraction
      }
    }
    this.createAttraction = () => {
      this.state.data.forEach(attraction => {
        var el = document.createElement('div')
        el.innerText = !this.noSideBar ? attraction.id + 1 : ''
        el.className = `marker ${this.noSideBar && 'marker-background'}`
        const attractionLocation = new mapboxgl.Marker(el, {
          anchor: 'center',
        })
          .setLngLat([attraction.map.lat, attraction.map.lng])
          .addTo(this.map)
        el.addEventListener('click', () => {
          this.goToAttraction(attraction.id)
        })
        this.attractions.push(attractionLocation)
      })
      this.boundsView()
    }

    this.createAttraction()
  }

  flyToAttraction = index => {
    const currentAttraction = this.state.data[index]
    this.currentAttraction = currentAttraction
    let coordinates = [
      this.currentAttraction.map.lat,
      this.currentAttraction.map.lng,
    ]

    this.map.flyTo({
      center: coordinates,
      offset: [0, 100],
      zoom: 12,
    })
  }

  goToAttraction = index => {
    this.reset()
    this.setActiveAttraction(index)
    this.flyToAttraction(index)
    this.createPopUp(index)
  }

  decodeHtml(content) {
    var txt = document.createElement('textarea')
    txt.innerHTML = content
    return txt.value
  }

  truncate() {
    let content = striptags(this.currentAttraction.description)
    content = content.replace(/(\r\n|\n|\r)/gm, ' ')
    content = content.replace(/[ ]{2,}/gi, ' ')
    content = this.decodeHtml(content)
    function truncate(str, no_words) {
      return str
        .split(' ')
        .splice(0, no_words)
        .join(' ')
    }

    return truncate(content, 15).replace(/\W*\s(\S)*$/, '...')
  }

  removePopups() {
    const popUps = document.getElementsByClassName('mapboxgl-popup')
    if (popUps[0]) popUps[0].remove()
  }

  createPopUp() {
    let imageURL = ''
    this.props.noSideBar
      ? (imageURL = this.currentAttraction.imageURL)
      : (imageURL = this.currentAttraction.images[0].srcSet.medium_large)

    new mapboxgl.Popup({
      closeOnClick: false,
      className: 'popUp',
      anchor: 'bottom',
    })
      .setLngLat([
        this.currentAttraction.map.lat,
        this.currentAttraction.map.lng,
      ])

      .setHTML(
        `<div class="wrapper  ${this.noSideBar}">
            <div class="img__wrapper">
            <img src="${imageURL}"/>
            </div>
          <div class="text__wrapper " ${this.noSideBar}>
            ${!this.noSideBar ? '' : '<h3>30 Jun 2019</h3>'}

            <h1 ${this.noSideBar}>
            ${this.currentAttraction.stopName}
            </h1>
            <h2 class="content ${this.noSideBar}">
            ${this.truncate()}
            </h2>

            ${
              !this.noSideBar
                ? `<a href="#activity${this.currentAttraction.id +
                    1}" class="link">
                  Jump to this stop <i />
                </a>`
                : ''
            }
            ${
              !this.noSideBar
                ? ''
                : `<a href="${this.currentAttraction.path}" class="link">
            Check it out! <i />
          </a>`
            }
          </div>
        </div>`
      )
      .addTo(this.map)
  }

  render() {
    return (
      <Container>
        <LoadExternalScript
          src="https://cdnjs.cloudflare.com/ajax/libs/mapbox-gl/0.53.1/mapbox-gl.js"
          id="example"
          onLoad={() => this.createMap()}
        />
        <Map
          noSideBar={this.noSideBar}
          id="map"
          activeAttractionID={this.state.activeAttractionID}
          index={this.currentAttraction}
        />

        {!this.noSideBar && (
          <Sidebar>
            <Listing
              id="listings"
              attraction={this.state.data}
              activeAttractionID={this.state.activeAttractionID}
              goToAttraction={this.goToAttraction}
              activitiesData={this.AttractionsData}
              attractionsLenght={this.Attractions.length}
            />
          </Sidebar>
        )}
      </Container>
    )
  }
}
