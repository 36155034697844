import styled from 'styled-components'

export const IntroContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  border-bottom: 1px solid #e0d6c8;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 30px;
  }
  @media (min-width: 1280px) {
    padding-bottom: 60px;
    margin-bottom: 60px;
    border-bottom: 1px solid #000;
  }

  > p {
    font-family: Freight Big;
    color: #212c1f;
    font-size: 24px;
    line-height: 117%;
    width: 100%;
    margin-bottom: 0;

    @media (min-width: 768px) {
      padding: 0;
      max-width: 1012px;
      border-bottom: none;
      font-size: 32px;
      margin-bottom: 0px;
      line-height: 125%;
    }
  }
`

export const InfoWrapper = styled.div`
  flex: 0 0 auto;
  padding-left: 8px;
  display: grid;
  grid-row-gap: 8px;
  grid-template-columns: 1fr;
  overflow: hidden;

  @media (min-width: 768px) {
    padding: 0 60px;
  }

  @media (min-width: 1024px) {
    padding: 0;
    padding-left: 40px;
    max-height: 130px;
    grid-template-columns: 290px 200px;
    grid-template-rows: auto auto auto;
    grid-auto-flow: column;
  }
`

export const Item = styled.div`
  position: relative;
  display: flex;
  /* padding: 8px 0; */
  align-items: center;

  @media (min-width: 768px) {
    padding-left: 25px;
    ::before {
      content: '';
      position: absolute;
      left: 0;
      margin: 0;
      background: #e0d6c8;
      height: 600%;
      width: 1px;
      display: block;
    }
  }

  svg {
    margin: 0;
    margin-right: 10px;
    width: 22px;
  }
  p {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: -0.1px;
    line-height: 100%;
    color: #212c1f;
    text-transform: none;
    margin: 0;

    a {
      color: #212c1f !important;
      font-weight: 400;
      cursor: pointer;
      :hover {
        color: #a55331 !important;
      }
    }
  }
`

export const DownloadPDF = styled(Item)`
  div {
    display: flex;
    opacity: ${props => (props.opaque ? '1' : '0.14')};
    pointer-events: ${props => (props.opaque ? 'all' : 'none')};
    align-items: center;
  }
`
export const FitnessLevel = styled.span`
  width: 8px;
  height: 8px;
  background: #ef9e24;
  border-radius: 50%;
  display: inline-block;
  margin: 0 8px;
`

export const ItineraryContainer = styled.div`
  > div {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    :first-child {
      padding-top: 0;
      margin-top: 100px;
    }

    > div:nth-child(2n) {
      /* border: 2px solid red; */
      > div:nth-child(2n) {
        > div:nth-child(1) {
          > div:nth-child(1) {
            order: 0;
            justify-self: flex-end;

            @media (min-width: 1340px) {
              padding-left: 40px;
            }

            ::after {
              left: auto;
              right: -1px;
            }

            > div:nth-child(1) {
              left: auto;
              right: 0px;
              margin: 0;
              transform: translateX(50%);
            }
          }
        }
      }
    }
  }
  > div:nth-child(2n) {
    /* border: 2px solid blue; */
    > div:nth-child(2n) {
      > div:nth-child(1) {
        > div:nth-child(2) {
          > div {
            @media (min-width: 768px) {
              padding: 0 40px 0 50px;
            }

            @media (min-width: 1024px) {
              padding: 0 50px 0 80px;
            }
          }
        }
      }
    }
  }
`

//activity.js component

export const ActivityWrapper = styled.div`
  border-bottom: 1px solid black;
  margin-bottom: 35px;

  @media (min-width: 768px) {
    overflow: ${props => (props.gallery ? 'visible' : 'hidden')};
    grid-template-columns: 1fr 1fr;
    border-bottom: none;
    padding-top: 80px;
    padding-bottom: ${props => props.booking && '100px'};
    margin: 0;
    position: relative;

    ::after {
      position: absolute;
      z-index: -2;
      left: 50%;
      top: ${props =>
        props.gallery ? 'auto' : props.firstActivity ? '80px' : '0'};
      bottom: ${props => props.gallery && '0'};
      content: '';
      width: 1px;
      height: 100%;
      background: #b9bab4;
      /* #b9bab4; */
    }
  }
`

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  padding-bottom: 30px;
  position: relative;

  @media (min-width: 768px) {
    padding-bottom: 0px;

    overflow: hidden;
    grid-template-columns: 1fr 1fr;
  }
`

export const TextContainer = styled.div`
  padding: 0 8px;
  position: relative;

  @media (min-width: 768px) {
    order: 1;
    padding: 20px 50px 0 50px;
    flex-basis: 50%;
  }
  @media (min-width: 1024px) {
    max-width: 550px;
    padding: 20px 80px 0 80px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 26px;
`

export const NumberedBox = styled.div`
  position: absolute;
  background: #fbf9f6;
  left: 8px;
  top: 15px;
  height: 32px;
  width: 32px;

  flex-shrink: 0;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-right: 15px;

  @media (min-width: 768px) {
    left: 0;
    top: 45px;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
  }
  h5 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 50px;

  @media (min-width: 768px) {
    padding-left: 0;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #212c1f;

    @media (min-width: 768px) {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  h2 {
    font-size: 24px;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }
`

export const BookTicketsContainer = styled.div`
  margin-top: 35px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 35px auto;

  @media (min-width: 768px) {
    margin: 0 auto;
    padding-bottom: ${props => (props.lastActivity ? '0' : '60px')};
    :after {
      position: absolute;
      z-index: -1;
      left: 50%;
      bottom: 0;

      content: '';
      width: 1px;
      height: 60px;
      background: #b9bab4;
      display: ${props => (props.lastActivity ? 'none' : 'block')};
    }
  }

  h3 {
    font-family: Freight Big;
    text-transform: none;
    font-size: 32px;
    color: #212c1f;
    letter-spacing: 0;
    text-align: center;
    line-height: 100%;

    @media (min-width: 768px) {
      font-size: 48px;
      margin-bottom: 12px;
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    line-height: 100%;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 18px;
    }
    a {
      color: #212c1f;
      :hover {
        color: #a55331 !important;
      }

      ::before {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        /* border: 2px solid red; */
      }
    }
  }
`

export const TicketIconBox = styled.div`
  width: 40px;
  height: 40px;
  background: #a55331;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    margin-bottom: 25px;
  }

  svg {
		width:16px;
    path {
        fill: white;
      }
    }
  }
`
export const CarouselContainer = styled.div`
  overflow: hidden;
  width: 100%;
  position: Relative;
	margin-bottom: ${props => (props.custom ? '30px' : '40px')};
	margin-top: ${props => (props.custom ? '30px' : '0px')};

  @media (min-width: 768px) {
	margin-bottom: ${props => (props.custom ? '50px' : '0px')};

    padding: ${props => (props.custom ? '0' : '0 40px 0 40px')};
  	margin-top: ${props => (props.custom ? '50px' : '60px')};
    /* margin-top: ${props => (props.firstActivity ? '0' : null)}; */

    :after {
      position: absolute;
      z-index: -1;
      left: 50%;
			top: -300px;
			display: ${props => (props.custom ? 'none' : null)};
			

      content: '';
      width: 1px;
      height: 2000px;
      background: #b9bab4;
    }
  }

  @media (min-width: 1024px) {
	margin-bottom: ${props => (props.custom ? '60px' : '0px')};

    padding: ${props => (props.custom ? '0' : '0 50px 0 50px')};
		margin-top: ${props => (props.custom ? '80px' : '80px')};

    /* margin-top: ${props => (props.firstActivity ? '0' : null)}; */
  }

#countdown {
  position: absolute;
  margin: auto;
  height: 40px;
  width: 40px;
  text-align: center;
	top:-2px;
	left:6px;
}


svg {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 40px;
  height: 40px;
  transform: rotateY(0deg) rotateZ(-88deg);
}

svg circle {
	display:none;
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: square;
  stroke-width: 4.5px;
  stroke: white;
  fill: none;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 113px;
  }
  to {
    stroke-dashoffset: 0px;
  }
}



`

export const Caption = styled.figcaption`
  max-width: 780px;
  margin: 0 auto;
  border-left: 1px solid #e0d6c8;
  font-size: 12px;
  padding: 20px 20px 20px 20px;
  opacity: ${props => (props.hide ? 0 : 1)};
  @media (min-width: 768px) {
    font-size: 14px;
  }
`
export const CarouselWrapper = styled.div`
  background: white;
  margin: 0 auto;
  position: relative;
  width: 100%;
  padding-top: 55.48%;
  position: relative;
  /* max-width: 1094px; */

  figure {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
  }

  .slick-list {
    pointer-events: none;
  }

  .slick-slider {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0;
    display: block !important;

    ::after {
      content: '';
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 1%,
        rgba(0, 0, 0, 0.6) 98%
      );
      z-index: 0;
      width: 100%;
      height: 60%;
      position: Absolute;
      bottom: 0;
      right: 0;
    }
  }

  .slick-dots {
    position: absolute !important;
    bottom: 25px !important;
    right: 15px !important;

    @media (min-width: 1024px) {
      bottom: 40px !important;
      right: 30px !important;
    }

    li {
      height: auto !important;
      width: auto !important;
      margin: 0 !important;
      position: relative;
      padding: 0 10px !important;

      @media (min-width: 1024px) {
        padding: 0px 10px !important;
      }

      h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid transparent;
        width: 34px;
        height: 34px;
        margin: 0;
        transition: 400ms;

        @media (min-width: 1024px) {
          width: 34px;
          height: 34px;
        }
      }

      &.slick-active {
        h4 {
          border: 1px solid white;
        }
        svg circle {
          display: block;
          animation: countdown linear forwards;
          animation-duration: 6490ms;
          animation-delay: 0;
        }
      }
    }
  }
`

export const ImageContainer = styled.div`
  display: grid;
  grid-gap: 20px;

  @media (min-width: 768px) {
    padding: 0 50px 0 40px;
    grid-gap: 50px;
  }

  @media (min-width: 1024px) {
    padding: 0 80px 0 50px;
  }
`

export const LastActivityLine = styled.div`
  @media (min-width: 768px) {
    display: ${props => (props.lastActivity ? 'block' : 'none')};
    width: 100%;
    height: 1px;
    background: #212c1f;
    margin: 80px 0 0;
  }
`

export const CarouselPage = styled.h4`
  font-weight: 500;
  color: white;
`

export const PlaceholderDiv = styled.div`
  width: 1px;
  position: Absolute;
  height: 1px;
  background: transparent;
  opacity: 0;
`

export const VideoWrapper = styled.div`
  > div {
    height: 100% !important;
    width: 100% !important;
    transform: scale(1.025);
  }
`

export const Note = styled.div`
  padding: 20px;

  @media (min-width: 768px) {
    padding: 80px;
  }
`

export const OutCalendar = styled.div`
  border: 2px solid #a55331;
  border-radius: 3px;
  width: 59px;
  height: 43px;
  margin-right: 13px;

  @media (min-width: 768px) {
    margin-right: 30px;
    width: 78px;
    height: 67px;
  }
  @media (min-width: 1024px) {
    margin-right: 40px;
  }
`
export const Calendar = styled.div`
  margin-top: 3px;
  border-top: 2px solid #a55331;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    margin-top: 7px;
  }

  p {
    font-weight: 700;
    font-size: 20px;
    color: #a55331;
    letter-spacing: 0;
    text-align: center;
    line-height: 100%;
    margin-bottom: 0;

    @media (min-width: 768px) {
      font-size: 36px;
    }
  }
  span {
    font-weight: 500;
    font-size: 10px;
    color: #212c1f;
    line-height: 100%;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 12px;
    }
  }
`
