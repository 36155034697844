import styled from 'styled-components'

import MarkerSVG from '../../img/svg/marker-icon.svg'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  padding-bottom: 60px;
  border-bottom: 1px solid #212c1f;

  @media (min-width: 1024px) {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 80px;

    display: flex;
    flex-direction: row;
  }
`

const Map = styled.div`
  position: relative;
  overflow: hidden;
  height: 244px;

  @media (min-width: 667px) {
    height: 366px;
  }

  @media (min-width: 1024px) {
    position: ${props =>
      props.noSideBar ? 'relative' : 'absolute !important;'};

    top: 0;
    bottom: 0;
    width: ${props => (props.noSideBar ? '100%;' : '61%')};
    min-height: 500px;
    height: calc(100% - 60px);
  }

  .mapboxgl-marker {
    position: absolute;
    background-color: #212c1f;

    border: ${props => (!props.noSideBar ? '1px solid #778475;' : 'none')};

    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

    :hover {
      background-color: #a55331;
    }

    @media (min-width: 1024px) {
      padding-top: ${props => (!props.noSideBar ? '8px' : '0')};
      width: ${props => (!props.noSideBar ? '36px' : '42px')};
      height: ${props => (!props.noSideBar ? '36px' : '42px')};
    }

    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: -0.11px;
    text-align: center;
    line-height: 20px;
    padding-top: 2px;
  }

  .marker-background {
    background-image: url(${MarkerSVG});
    background-position: center;
    background-size: 50%;
    cursor: pointer;
  }

  .mapboxgl-popUp {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }

  .mapboxgl-popup-content {
    padding: 0;
    overflow: hidden;
    width: 95%;
    margin: 0 auto;
  }

  .wrapper {
    height: auto;

    @media (min-width: 667px) {
      max-width: 468px;
    }

    .img__wrapper {
      position: absolute;
      display: block;
      width: 25%;
      height: 100%;
      left: 0;

      @media (min-width: 667px) {
        width: 50%;
      }

      img {
        width: 100%;
        margin-bottom: 0;
        object-fit: cover;
        height: 100%;
      }
    }

    .text__wrapper {
      margin-left: 25%;
      padding-bottom: 20px;

      @media (min-width: 667px) {
        margin-left: 50%;
      }
    }

    h3 {
      margin: 0;
      font-weight: 400;
      font-size: 10px;
      color: #545c52;
      letter-spacing: 0.43px;
      line-height: 20px;
      padding-top: 15px;
      padding-left: 15px;

      @media (min-width: 667px) {
        font-size: 12px;
        padding-top: 20px;
        padding-left: 20px;
      }
    }

    h1,
    .link {
      font-weight: ${props => (!props.noSideBar ? '500' : '700')};
      font-size: 12px;
      color: #212c1f;
      line-height: ${props => (!props.noSideBar ? '18px' : '16px')};
      letter-spacing: ${props => (!props.noSideBar ? '0' : '-0.14px')};
      text-align: left;
      ${props => (!props.noSideBar ? '0' : '16px')};
      padding-top: ${props => (!props.noSideBar ? '20px' : '0')};
      padding-left: 15px;
      margin: 0;
      text-transform: none;
      padding-right: 15px;
      text-transform: ${props => (!props.noSideBar ? '' : 'uppercase')};

      i {
        display: ${props => (!props.noSideBar ? 'inline-block;' : 'none')};
        border: solid black;
        border-width: 0 3px 3px 0;
        padding: 3px;
        margin-left: 3px;
        margin-bottom: 2px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }

      @media (min-width: 667px) {
        padding-top: ${props => (!props.noSideBar ? '20px;' : '5px')};
        padding-left: 20px;
        font-size: ${props => (!props.noSideBar ? '15px' : '16px')};
      }
    }

    h2 {
      text-transform: none;
      font-weight: 300;
      font-size: 12px;
      color: #545c52;
      letter-spacing: 0;
      text-align: left;
      line-height: 20px;
      padding-top: 10px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 10px;
      margin: 0;

      a {
        pointer-events: none;
        font-weight: 300;
        color: #545c52;
      }

      @media (min-width: 667px) {
        padding-top: ${props => (!props.noSideBar ? '15px;' : '10px')};
        padding-left: 20px;
        padding-right: 15px;
        margin-bottom: 5px;
        font-size: 15px;
      }
    }
    .link {
      font-weight: 500;
      text-transform: ${props => (!props.noSideBar ? '' : 'none')};
      padding-top: 0;
      margin-top: 10px;
      position: relative;
    }
  }

  .mapboxgl-popup-tip {
    width: 0;
    height: 0;
    border: 0 solid transparent;
    z-index: 0;
    margin-top: 33px;
  }
`

const Sidebar = styled.div`
  margin-top: 25px;
  /* min-height: 500px; */

  @media (min-width: 667px) {
    position: relative;
    margin: 0 auto;
    margin-top: 30px;
  }

  @media (min-width: 1024px) {
    position: relative;
    top: 0;
    right: 0;
    width: 33.333%;
    z-index: 1;
    background-color: #fbf9f6;
    margin-top: 0;
    margin-left: 66.666%;
    min-height: 500px;
  }
`

const Hide = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #212c1f;
  letter-spacing: -0.08px;
  line-height: 20px;
  cursor: pointer;
  padding-bottom: 15px;

  &.open {
    padding-bottom: 0px;
  }

  img {
    float: left;
    width: 16px;
    height: 9px;
    margin: 0 auto;
    margin-right: 10px;
    margin-top: 5px;
    animation-name: ${props =>
      props.active ? 'MenuArrowClose' : 'MenuArrowOpen'};
    animation-duration: 0.8s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    @keyframes MenuArrowOpen {
      0% {
        transform: rotateX(0deg);
        opacity: 1;
      }
      25% {
        transform: rotateX(0deg);
        opacity: 0;
      }
      50% {
        transform: rotateX(180deg);
        opacity: 0;
      }
      75% {
        opacity: 0;
      }
      100% {
        opacity: 1;
        transform: rotateX(180deg);
      }
    }

    @keyframes MenuArrowClose {
      0% {
        transform: rotateX(180deg);
        opacity: 1;
      }
      25% {
        opacity: 0;
      }
      50% {
        transform: rotateX(180deg);
        opacity: 0;
      }
      75% {
        transform: rotateX(0deg);
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

const Menu = styled.div`
  margin-top: 26px;
  display: none;
  overflow: hidden;

  &.open {
    display: block;
  }

  @media (min-width: 1024px) {
    margin-top: 8px;
  }
`
const ItemWrapper = styled.div`
  cursor: pointer;
  border-right: none;
  vertical-align: top;

  &:last-child .innerNumberWrapper :after {
    display: none;
  }
`

const Day = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #212c1f;
  letter-spacing: -0.09px;
  line-height: 14px;
  margin-bottom: ${props => (props.attractionsLenght <= '7' ? '25px' : '15px')};
  position: relative;

  ::after {
    content: '';
    display: block;
    width: calc(100% - 45px);
    height: 1px;
    background: #b9bab4;
    position: absolute;
    left: 45px;
    top: 50%;
  }

  @media (min-width: 1024px) {
    border-right: none;
    margin-left: 0;
    font-size: 14px;
    letter-spacing: -0.11px;
    line-height: 14px;
  }
`

const NumberWrapper = styled.div`
  background: #fbf9f6;
  border: 1px solid #b9bab4;

  border-color: ${props =>
    props.activeAttractionID === props.id ? '#212c1f' : '#b9bab4'};
  width: 32px;
  height: 32px;

  ::after {
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    background: #b9bab4;
    position: absolute;
    left: 15px;
    top: 32px;

    :last-child :after {
      display: block;
    }
  }

  @media (min-width: 1024px) {
    margin-top: ${props => (props.attractionsLenght <= '7' ? '0' : '6px')};
    width: ${props => (props.attractionsLenght <= '7' ? '40px' : '26px')};
    height: ${props => (props.attractionsLenght <= '7' ? '40px' : '26px')};
    ::after {
      top: ${props => (props.attractionsLenght <= '7' ? '40px' : '32px')};
      left: ${props => (props.attractionsLenght <= '7' ? '20px' : '13px')};
    }
  }
`
const Item = styled.div`
  display: block;
  text-decoration: none;
  margin-top: 14px;
  cursor: pointer;

  :hover .innerNumberWrapper {
    border-color: #212c1f;
  }

  @media (min-width: 1024px) {
    margin-top: ${props => (props.attractionsLenght <= '7' ? '20px' : '8px')};
  }
  p {
    font-weight: 500;
    font-size: 14px;
    color: #212c1f;
    letter-spacing: -0.08px;
    text-align: center;
    line-height: 18px;
    margin: ${props =>
      props.attractionsLenght <= '7' ? '5px auto' : '5px auto'};

    @media (min-width: 1024px) {
      font-size: ${props => (props.attractionsLenght <= '7' ? '18px' : '12px')};
      margin: ${props =>
        props.attractionsLenght <= '7' ? '9px auto' : '4px auto'};
    }
  }

  h4 {
    padding: 0;
    margin: 0;
    font-family: Freight Big;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
    text-transform: none;

    @media (min-width: 1024px) {
      font-size: ${props => (props.attractionsLenght <= '7' ? '24px' : '22px')};
      line-height: 30px;
      padding-top: ${props => (props.attractionsLenght <= '7' ? '4px' : '0')};
    }
  }

  :hover h4 {
    color: #a55331;
  }
`

const ListWrapper = styled.div`
  margin-bottom: 25px;

  @media (min-width: 1024px) {
  }
  margin-bottom: ${props => (props.attractionsLenght <= '7' ? '30px' : '18px')};
`

const List = styled.div`
  max-width: 500px;
  display: grid;
  grid-template-columns: 10% 85%;
  grid-column-gap: 5%;
  grid-row-gap: 0;
  align-items: top;

  list-style-type: none;
  position: relative;
  color: ${props =>
    props.activeAttractionID === props.id ? '#A55331' : '#212c1f'};

  @media (min-width: 1024px) {
    margin-left: 0;
  }

  @media (min-width: 1024px) {
    text-decoration: none;
    margin-top: ${props => (props.attractionsLenght <= '7' ? '20px' : '0')};
  }
`

const Listings = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 60px;
`

export {
  Container,
  Menu,
  Hide,
  Sidebar,
  Map,
  ItemWrapper,
  Day,
  NumberWrapper,
  Item,
  ListWrapper,
  List,
  Listings,
}
